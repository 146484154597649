<template>
  <div>
    <el-breadcrumb separator-icon="ArrowRight" style="margin: 16px">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card style="margin: 15px; min-height: calc(100vh - 111px)">
      <div>
        <!--    功能区-->
        <div style="margin: 10px 0">
          <!--    搜索区-->
          <div style="margin: 10px 0">
            <div>
              <!-- 年份选择器 -->
              <el-date-picker
                  v-model="selectedYear"
                  type="year"
                  placeholder="选择年份"
                  format="yyyy"
              ></el-date-picker>

              <!-- 月份选择器 -->
              <el-date-picker
                  v-model="selectedMonth"
                  type="month"
                  placeholder="选择月份"
                  format="yyyy-MM"
              ></el-date-picker>
            </div>
          </div>
        </div>
        <!--    表格-->
        <el-table v-loading="loading" :data="tableData" border max-height="705" show-overflow-tooltip
                  style="width: 100%">
          <el-table-column label="#" type="index"/>
          <el-table-column label="月份" prop="creationtime" width="100"/>
          <!-- 在表头添加筛选器 -->
            <template slot-scope="{ column }">
              <el-date-picker
                v-model="creationtimeFilter"
                type="month"
                placeholder="筛选月份"
                format="yyyy-MM"
                style="width: 100%"
                @change="handleCreationtimeFilterChange"
              ></el-date-picker>
            </template>
          <el-table-column label="订单数" prop="ordernumber" width="100"/>
          <el-table-column label="提成总额" prop="payment" width="100"/>
          <el-table-column label="银行卡号" prop="yhnumber" width="100"/>
          <el-table-column label="结账状态" prop="remark" width="100"/>
<!--                操作栏-->
          <el-table-column label="明细" width="100px" fixed="right">
            <template #default="scope">
              <el-button  icon="view" type="primary" @click="load1(scope.row)"></el-button>
            </template>
          </el-table-column>
        </el-table>


        <!--分页-->
        <div style="margin: 10px 0">
          <el-pagination
              v-model:currentPage="currentPage"
              :page-size="pageSize"
              :page-sizes="[10, 20]"
              :total="total"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
        <!--      弹窗-->
        <div>
          <el-dialog v-model="dialogVisible" title="查看" width="30%" >
            <el-table v-loading="loading" :data="tableData1" border max-height="705" show-overflow-tooltip
                      style="width: 100%">
              <el-table-column label="#" type="index"/>
              <el-table-column label="订单类型" prop="serve" width="100"/>
              <el-table-column label="订单编号" prop="ordernumber" width="100"/>
              <el-table-column label="客户id" width="100"  prop="customerID"/>
              <el-table-column label="订单创建时间" prop="creationtime" width="100"/>
              <el-table-column label="审核时间" prop="deliverytime" width="100"/>
            </el-table>
          </el-dialog>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script src="@/assets/js/ApplyChangeRoom.js"></script>